import React, { FunctionComponent, useEffect, useState } from "react"
import classNames from "classnames"
import { useTopics } from "../../hooks/use-topics"
import styles from "./topic-list.module.scss"
import { Link } from "@components/link/link"
import { PathPrefix } from "@core/constants"
import { useTopicLists } from "@hooks/use-topic-code-data"
import {
  mainSearchIndexName,
  searchClient
} from "@features/search/search-constants"
import { TopicListItem } from "@features/topics/topic-types"
import { Skeleton } from "@components/skeleton/skeleton"

const Topic: FunctionComponent<{ item: TopicListItem }> = ({ item }) => {
  const searchResultsIndex = searchClient.initIndex(mainSearchIndexName)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hitsLength, setHitsLength] = useState<number>(0)

  const facetFilter = useTopicLists(item.name).reduce<string[]>(
    (acc, curr) => {
      return [...acc, `fields.primaryTopic.fields.name:${curr}`]
    },
    [`fields.primaryTopic.fields.name:${item.name}`]
  )

  useEffect(() => {
    searchResultsIndex
      .search("", {
        facetFilters: [facetFilter]
      })
      .then(({ nbHits }) => {
        setHitsLength(nbHits)
        setIsLoading(false)
      })
      .catch((error) => console.log(error))
  }, [])

  if (!isLoading && !hitsLength) {
    return null
  }

  return (
    <li
      className={classNames(styles.topic, styles[`tier${item.tier}`])}
      key={item.slug}
    >
      <Link className={styles.link} to={`${PathPrefix.Topics}/${item.slug}`}>
        {item.name}
      </Link>
      {isLoading ? (
        <Skeleton className={styles.skeleton} width="32px" height="20px" />
      ) : (
        <span className={styles.entries}>{hitsLength}</span>
      )}
    </li>
  )
}

export const TopicList: FunctionComponent = () => {
  const { topicLists } = useTopics()

  return (
    <div className={styles.container}>
      {topicLists.map((list) => (
        <section key={list.slug} className={styles.topicList}>
          <h2 className={styles.sectionTitle}>{list.name}</h2>
          <ul className={styles.topics}>
            {list.items.map((item) => (
              <Topic key={`${item.name}-${item.tier}`} item={item} />
            ))}
          </ul>
        </section>
      ))}
    </div>
  )
}
