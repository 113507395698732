import { graphql, useStaticQuery } from "gatsby"
import { TopicCodesListQuery } from "../../graphql/types"

interface TopicNode {
  name: string
  topicCode: string
}

export const useTopicLists = (parentTopic: string): string[] => {
  const data: TopicCodesListQuery = useStaticQuery(graphql`
    query TopicCodesList {
      allContentfulTopic {
        nodes {
          name
          topicCode
        }
      }
    }
  `)

  const list = data.allContentfulTopic.nodes
  const codeTitleMap = new Map<string, string>()
  const topicsMap = new Map<string, Set<string>>()

  list.forEach((topicNode) => {
    const { name, topicCode } = topicNode as TopicNode

    codeTitleMap.set(topicCode, name)

    if (!topicsMap.has(name)) {
      topicsMap.set(name, new Set<string>())
    }
  })

  codeTitleMap.forEach((topic, code) => {
    const codeList = code.split(".")
    let currCode = codeList[0]

    for (let i = 1; i <= codeList.length; i++) {
      const codeNum = codeList[i]
      const currTopic = codeTitleMap.get(currCode)

      if (currTopic && currTopic != topic) {
        topicsMap.get(currTopic)?.add(topic)
      }

      currCode += `.${codeNum}`
    }
  })

  return [...(topicsMap.get(parentTopic)?.values() as IterableIterator<string>)]
}
