import { useContext, useEffect, useMemo } from "react"
import { TopicListFragment } from "../../../../graphql/types"
import {
  mapProductTopicsToView,
  QueryResultDataNode
} from "../mappers/product-topics-mapper"
import { mapSourceTopicsToView } from "../mappers/topics-mapper"
import { TopicsContext } from "../topics-context"

export const useTopicListData = (
  data: TopicListFragment,
  productNodes: QueryResultDataNode[]
): void => {
  const { setTopicLists, setTopicMap } = useContext(TopicsContext)

  const productTopics = useMemo(
    () => mapProductTopicsToView(productNodes),
    [productNodes]
  )

  useEffect(() => {
    if (data) {
      const { topicMap, topicLists } = mapSourceTopicsToView(
        data,
        productTopics
      )

      setTopicLists(topicLists)
      setTopicMap(topicMap)
    }
  }, [data, productTopics, setTopicLists, setTopicMap])
}
