import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { TopicsPageQuery } from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { Page } from "@components/page/page"
import { IndexHeader } from "@components/index-header/index-header"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { TopicList } from "@features/topics/components/topic-list/topic-list"
import { useTopicListData } from "@features/topics/hooks/use-topic-list-data"
import { QueryResultDataNode } from "@features/topics/mappers/product-topics-mapper"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { PageOpenGraphImage } from "@core/constants"

export const Topics: FunctionComponent<PageProps<TopicsPageQuery>> = ({
  data,
  ...props
}) => {
  const { header, metadata } = useIndexPageData("topics")

  useTopicListData(
    data!.allContentfulTopic!,
    data?.allProduct?.nodes as QueryResultDataNode[]
  )

  return (
    <Page
      {...props}
      metadata={{ ...metadata, openGraphImage: PageOpenGraphImage.Topics }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Topics
      }}
    >
      <IndexHeader header={header} />
      <TopicList />
    </Page>
  )
}

export default Topics

export const query = graphql`
  query TopicsPage {
    allContentfulTopic(sort: { fields: topicCode, order: ASC }) {
      ...TopicList
    }
    allProduct {
      nodes {
        topics {
          topicCode
        }
      }
    }
  }
`
