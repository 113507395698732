import { Maybe, ContentfulTopic } from "../../../../graphql/types"

export type ProductTopics = Record<string, number>

export interface QueryResultDataNode {
  topics?: Maybe<Pick<ContentfulTopic, "topicCode">>[] | null | undefined
}

export const mapProductTopicsToView = (
  nodes: QueryResultDataNode[]
): ProductTopics => {
  const map: ProductTopics = {}

  nodes.forEach((node) => {
    if (node.topics && node.topics.length) {
      node.topics.forEach((topic) => {
        if (topic?.topicCode) {
          const oldValue = map[topic.topicCode] || 0

          map[topic.topicCode] = oldValue + 1
        }
      })
    }
  })

  return map
}
